import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import {
    Card as MagritteCard,
    Text as MagritteText,
    VSpacing as MagritteVSpacing,
    VSpacingContainer as MagritteVSpacingContainer,
} from '@hh.ru/magritte-ui';
import { H2Section } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import LoopCounterParser from 'Modules/LoopCounterParser';
import { responseStreakUpdate } from 'lux/models/applicantResponseStreaks';
import { TopicSource } from 'lux/models/negotiationTopic.types';
import ActivityCardAnimated from 'src/components/Applicant/ActivityCard/ActivityCardAnimated';
import ResponseCoverLetter from 'src/components/Applicant/Response/CoverLetter';
import ResponseChatBotBloko from 'src/components/Applicant/Response/ResponseChatBot';
import ResponseChatBotMagritte from 'src/components/Applicant/Response/ResponseChatBotMagritte';
import SearchResponsesStreak from 'src/components/Applicant/Response/SearchResponsesStreak';
import VacancyBodyResponseCounter from 'src/components/Applicant/Response/VacancyBodyResponseCounter';
import VacancyActionResponse, { VacancyActionResponseKind } from 'src/components/VacancyView/VacancyActionResponse';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';

import matchChatbotResponseCounter from 'src/components/Applicant/Response/RespondedSuccess/matchChatbotResponseCounter';

import styles from './vacancy-responded-success.less';

const TrlKeys = {
    title: 'vacancy.responded.success.title',
    description: 'vacancy.view.notify.response.chat',
    fromCallTitle: 'vacancy.responded.success.fromCall.title',
};

interface RespondedSuccessProps {
    defaultView?: 'vacancybody' | 'search';
    vacancyId: number;
    isRedesign?: boolean;
    isMagritteExp?: boolean;
}

const RespondedSuccess: TranslatedComponent<RespondedSuccessProps> = ({
    defaultView,
    vacancyId,
    isRedesign,
    isMagritteExp,
    trls,
}) => {
    const responseStreak = useSelector((state) => state.applicantResponseStreaks[vacancyId]);
    const [showNextStep, setNextStep] = useState(false);
    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);
    const isGamificationExp = useSelector((state) => state.isApplicantMainGamificationExp);
    const chatBot = useSelector((state) => state.chatBot);
    const dispatch = useDispatch();

    const isMagritteOnVacancyResponseExp = useExperiment('magritte_on_vacancy_response');

    const location = useSelector((state) => state.router.location);
    const lastTopic = responseStatus?.negotiations?.topicList.sort(
        (a, b) => b.creationTimeMillis - a.creationTimeMillis
    )[0];
    const sourcePhoneCall = lastTopic?.topicSource === TopicSource.PhoneCall;

    useEffect(() => {
        if (!defaultView && location.query?.responsesCount) {
            dispatch(
                responseStreakUpdate({
                    vacancyId,
                    data: {
                        responsesCount: parseInt(location.query.responsesCount, 10),
                        responsesRequired: parseInt(location.query.responsesRequired, 10),
                        vacancyId: `${vacancyId}`,
                    },
                })
            );
            window.history.replaceState(window.history.state, document.title, location.pathname);
        }
    }, [dispatch, defaultView, location, vacancyId]);

    if (defaultView) {
        return (
            <>
                {defaultView === 'search' && showNextStep && isGamificationExp && (
                    <ActivityCardAnimated vacancyId={vacancyId} />
                )}
                <ResponseCoverLetter
                    isMagritteExp={isMagritteExp}
                    isMagritteOnVacancyResponseExp={isMagritteOnVacancyResponseExp}
                    vacancyId={vacancyId}
                    setNextStep={setNextStep}
                    defaultView={defaultView}
                    isRedesign={isRedesign}
                />
                <SearchResponsesStreak
                    vacancyId={vacancyId}
                    visible={showNextStep}
                    defaultView={defaultView}
                    isMagritteExp={isMagritteExp}
                />
            </>
        );
    }

    const alreadyResponded = responseStatus?.usedResumeIds?.length > 0;
    const usedResumeHash = alreadyResponded
        ? responseStatus?.resumes[responseStatus.usedResumeIds[0]]?.hash
        : undefined;

    const needShowResponseChatBot = () => {
        if (!usedResumeHash) {
            return false;
        }

        if (chatBot && responseStreak) {
            const { completeStepCounter } = LoopCounterParser(
                responseStreak.responsesRequired,
                responseStreak.responsesCount
            );

            return matchChatbotResponseCounter(completeStepCounter);
        }

        return Boolean(chatBot);
    };

    const showResponseChatBot = needShowResponseChatBot();
    const titleTrl = sourcePhoneCall ? trls[TrlKeys.fromCallTitle] : trls[TrlKeys.title];
    const descriptionTrl = trls[TrlKeys.description];
    const vacancyActionResponse = (
        <VacancyActionResponse
            isSecondary
            RespondedNode={BlokoLink}
            kind={!defaultView || defaultView === 'vacancybody' ? VacancyActionResponseKind.AfterBody : undefined}
            isMagritteOnVacancyResponseExp={isMagritteOnVacancyResponseExp}
        />
    );

    if (isMagritteOnVacancyResponseExp) {
        return (
            <MagritteVSpacingContainer default={24}>
                <MagritteCard stretched showShadow padding={12} borderRadius={24}>
                    <MagritteCard stretched padding={12}>
                        <MagritteText typography="title-4-semibold">{titleTrl}</MagritteText>
                        <MagritteVSpacing default={8} />
                        <MagritteText typography="paragraph-2-regular" style="secondary">
                            {descriptionTrl}
                        </MagritteText>
                        <MagritteVSpacing default={12} />
                        <div className={classnames(styles.vacancyActions, styles.vacancyActionsApplicant)}>
                            {vacancyActionResponse}
                        </div>
                    </MagritteCard>
                    <ResponseCoverLetter isMagritteOnVacancyResponseExp vacancyId={vacancyId} />
                </MagritteCard>
                {showResponseChatBot && <ResponseChatBotMagritte usedResumeHash={usedResumeHash ?? ''} />}
                {responseStreak && <VacancyBodyResponseCounter isMagritte vacancyId={vacancyId} />}
            </MagritteVSpacingContainer>
        );
    }

    return (
        <>
            <div className={classnames(styles.shadowContainer, { [styles.shadowContainerRedesigned]: isRedesign })}>
                <H2Section>{titleTrl}</H2Section>
                <VSpacing base={1} />
                <Text>{descriptionTrl}</Text>
                <VSpacing base={1} />
                <div className={classnames(styles.vacancyActions, styles.vacancyActionsApplicant)}>
                    {vacancyActionResponse}
                </div>
                {showResponseChatBot && <ResponseChatBotBloko usedResumeHash={usedResumeHash} />}
                {responseStreak && <VSpacing base={showResponseChatBot ? 2 : 4} />}
                {responseStreak && <VacancyBodyResponseCounter vacancyId={vacancyId} />}
                <ResponseCoverLetter vacancyId={vacancyId} hasSeparator={!(responseStreak || showResponseChatBot)} />
            </div>
        </>
    );
};

export default translation(RespondedSuccess);
