import { EmployerBadges } from 'lux/models/employer/employerInfo';
import { Badge, BadgeType } from 'lux/models/vacancyView.types';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

const useGetCompanyHHRatingBadge = (badges?: EmployerBadges): Badge | undefined => {
    const isZP = useIsZarplataPlatform();

    if (isZP) {
        return undefined;
    }

    return badges?.badge.find((badge) => badge.type === BadgeType.EmployerHHRating);
};

export default useGetCompanyHHRatingBadge;
