import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { formatToReactComponent } from 'bloko/common/trl';

import { CurrencyType } from 'lux/models/currencies.types';
import Currency from 'src/components/Currency';
import translation from 'src/components/translation';
import { NARROW_NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

export interface CompensationProps {
    /** Зарплатные ожидания от */
    from?: number;
    /** Зарплатные ожидания до */
    to?: number;
    /** Код валюты */
    currencyCode: CurrencyType;
    /** Выводить "–" между значениями вместо от и до */
    dash?: boolean;
    /** Выводить сообщение "з/п не указана" */
    showNoSalaryMessage?: boolean;
    /** разделитель групп */
    groupSeparator?: string;
}

const TrlKeys = {
    to: 'vacancy.compensation.to',
    fromTo: 'vacancy.compensation.from.to',
    from: 'vacancy.compensation.from',
    noCompensation: 'vacancySearchResults.compensationNotSet',
};

const Compensation: TranslatedComponent<CompensationProps> = ({
    from,
    to,
    currencyCode,
    dash = false,
    showNoSalaryMessage = true,
    groupSeparator = NARROW_NON_BREAKING_SPACE,
    trls,
}) => {
    if (!from && !to && !showNoSalaryMessage) {
        return null;
    }

    let content;
    const formattedFrom = from ? NumberFormatter.format(String(from), { groupSeparator }) : null;
    const formattedTo = to ? NumberFormatter.format(String(to), { groupSeparator }) : null;

    if (formattedFrom && formattedTo) {
        if (dash) {
            content = <Currency prefix={`${formattedFrom} – ${formattedTo} `} value={currencyCode} />;
        } else {
            content = formatToReactComponent(trls[TrlKeys.fromTo], {
                '{0}': <>{formattedFrom}</>,
                '{1}': <>{formattedTo}</>,
                '{2}': <Currency value={currencyCode} />,
            });
        }
    } else if (formattedFrom && !formattedTo) {
        content = formatToReactComponent(trls[TrlKeys.from], {
            '{0}': <>{formattedFrom}</>,
            '{1}': <Currency value={currencyCode} />,
        });
    } else if (!formattedFrom && formattedTo) {
        content = formatToReactComponent(trls[TrlKeys.to], {
            '{0}': <>{formattedTo}</>,
            '{1}': <Currency value={currencyCode} />,
        });
    } else {
        content = trls[TrlKeys.noCompensation];
    }

    return <>{content}</>;
};

export default translation(Compensation);
