import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import styles from '../styles.less';

type ReturnType = {
    wideCardContainerClassName: string;
    narrowCardContainerClassName?: string;
    separateLineOnNarrowCardClassName?: string;
};

// Есть 2 вида карточки вакансии - узкая и широкая карточка
// Узкая используется на xs (а в Магрите на xs и s) и на странице карты вакансий (независимо от разрешения экрана)
// Внутри компонентов карточки используются классы-контейнеры из этого хука
// В них оборачиваются элементы, специфичные для широкой/узкой карточки
export const useCardContainerClassNames = (): ReturnType => {
    const isMapVacancySearch = useSelector((state) => state.isMapVacancySearch);
    const isMagritte = useMagritte();

    if (isMapVacancySearch) {
        return {
            wideCardContainerClassName: styles.hidden,
            narrowCardContainerClassName: undefined,
            separateLineOnNarrowCardClassName: styles.separateLine,
        };
    }
    return {
        wideCardContainerClassName: isMagritte ? styles.wideContainerMagritte : styles.wideContainer,
        narrowCardContainerClassName: isMagritte ? styles.narrowContainerMagritte : styles.narrowContainer,
        separateLineOnNarrowCardClassName: isMagritte ? styles.separateLineOnSMagritte : styles.separateLineOnXs,
    };
};
